<template>
	<v-app>
		<LayoutHomeFloatMenu v-if="showFloatMenu" />
		<LayoutHomeDrawer v-model="model" />
		<LayoutHomeInquireDrawer v-if="!checkInteriorStore" />
		<LayoutHomeInteriorstoreDrawer v-if="checkInteriorStore" />
		<LayoutHomeAppbar v-model="model" />
		<!--<LayoutHomeOverlayMenu v-model="overlay" />-->
		<v-main class="pa-0">
			<router-view
				class="home_main"
				:class="homeInteriorSection"
			/>
		</v-main>
		<LayoutHomeFooter />
		<CommonLoading v-if="loading" />
		<CommonDialog
			:dialog="dialog.dialog"
			:icon="dialog.icon"
			:title="dialog.title"
			:text="dialog.text"
			@close="closeDialog()"
		/>
		<CommonAlert />
		<CommonSnackbar />

		<!-- popup -->
		<div v-if="popupItems.length && checkPopup">
			<CommonDialogAppDialogNew
				:dialog="popupDialog"
				:items="popupItems"
				@close="popupDialog = false"
			/>
		</div>

		<div
			v-if="backsize && checkPopup"
			class="popupback"
			@click="closePopDim"
		></div>

		<v-btn
			v-show="floatingTopButton"
			color="primary"
			dark
			fixed
			bottom
			right
			fab
			class="mb-5"
			@click="common_scrollToTop"
		>
			<v-icon>mdi-chevron-up</v-icon>
		</v-btn>
	</v-app>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import common from '@/mixins/common'
import geolocation from '@/mixins/geolocation'
import localStorage from '@/utils/localStorage'

export default {
	name: 'Home',
	components: {},
	mixins: [common, geolocation],
	data: () => ({
		overlay: false,
		model: false,
		popupDialog: false,
		popupBack: false,
		homeInteriorSection: '',
	}),
	computed: {
		...mapGetters([
			'APP_GET_POPUP',
			'APP_GET_APP_STATE',
			'APP_GET_LOADING',
			'APP_GET_DIALOG',
			'APP_GET_PAGE_LOAD',
			'APP_GET_ISLOADPOPUP',
			'APP_GET_APP_WIDTH',
		]),

		backsize() {
			if (this.APP_GET_APP_WIDTH < 600 && this.popupDialog !== false) {
				return true
			} else {
				return false
			}
		},
		loading() {
			return this.APP_GET_LOADING
		},
		dialog() {
			return !this.APP_GET_DIALOG ? { dialog: false, icon: '', title: '', text: '' } : this.APP_GET_DIALOG
		},
		floatingTopButton() {
			let check = true

			if (this.APP_GET_APP_STATE === 'web') {
				// web페이지일 경우
				check = false
			} else {
				// path check _ hideList의 목록이 포함된 path인 경우
				const hideList = ['coohome', 'store', 'inquire']
				for (let i of hideList) {
					if (this.common_recentlyRouterPath.includes(i)) check = false
				}
			}

			return check
		},
		showFloatMenu() {
			return !this.common_recentlyRouterPath.includes('coohome')
		},
		popupItems() {
			console.log(this.APP_GET_POPUP, this.common_recentlyRouterPath, this.$route.path)
			return this.APP_GET_POPUP?.filter(x => x.location === this.$route.path) || []
		},
		checkPopup() {
			if (this.APP_GET_POPUP.length) {
				if (this.popupItems.length) {
					return this.$cookies.get(this.$route.path) !== 'false'
				}
			}
			return false
		},
		checkInteriorStore() {
			if (this.$route.path === '/interior/store') {
				return true
			} else {
				return false
			}
		},
	},
	watch: {
		popupItems(val) {
			if (val.length) this.popupDialog = true
		},
		popupback(val) {
			if (val === false) this.popupBack = false
		},
		checkInteriorStore(val) {
			if (val === true) {
				this.homeInteriorSection = 'interiorSection'
			} else {
				this.homeInteriorSection = ''
			}
		},
	},
	async created() {
		if (localStorage.accessToken.get()) await this.AUTH_ACT_GET_TOKEN('accessToken_confirm')
		if (!this.APP_GET_ISLOADPOPUP) await this.APP_ACT_POPUP()
	},
	mounted() {
		// this.geolocation_once()
		if (this.popupItems.length) this.popupDialog = true
		if (this.checkInteriorStore) {
			this.homeInteriorSection = 'interiorSection'
		} else {
			this.homeInteriorSection = ''
		}
	},
	methods: {
		...mapMutations(['APP_MU_DIALOG']),
		...mapActions(['AUTH_ACT_GET_TOKEN', 'APP_ACT_POPUP']),
		closeDialog() {
			this.APP_MU_DIALOG(null)
		},
		closePopDim() {
			this.popupDialog = false
		},
	},
}
</script>
<style scoped lang="scss">
.home_main {
	padding: 116px 0 50px !important;
}
.popupback {
	background: rgb(0 0 0 / 40%);
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 22;
}
@media all and (max-width: 959px) {
	.home_main {
		padding: 48px 0 50px !important;
		// height: 100%;
		// position: absolute;
	}
	.home_main.interiorSection {
		padding: 48px 0 0 !important;
	}
}
</style>
